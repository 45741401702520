<template>
  <v-chip
    v-if="record_lock?.user && record_lock.user.uuid != $auth.user().uuid"
    dark
    color="blue"
  >
    <v-avatar
      left
      color="white"
    >
      <v-icon
        small
        color="blue"
      >
        fa-solid fa-lock-alt
      </v-icon>
    </v-avatar>
    Locked by {{ record_lock?.user?.first_name }} {{ record_lock?.user?.last_name }}
  </v-chip>
</template>
<script>
export default {
  props: {
    uuid: {
      type: String,
      default: null,
    },
    recordLock: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      record_lock: this.recordLock || null,
      lockTimer: null,
    }
  },
  watch: {
    recordLock () {
      if (this.record_lock != this.recordLock) {
        this.lockRecord()
      }
    },
    record_lock () {
      this.$emit('update:lock', this.record_lock)
    },
  },
  mounted() {
    this.lockRecord()
    this.lockTimer = setInterval(() => {
      this.lockRecord()
    }, 30000)
  },
  beforeDestroy () {
    this.axios.post(`lab_results/${this.uuid}/unlock`)
    clearInterval(this.lockTimer)
  },
  methods: {
    lockRecord () {
      if (this.uuid != undefined) {
        this.axios.get(`lab_results/${this.uuid}/lock`)
          .then((res) => {
            if (res.data.user) {
              if (
                (!this.record_lock && res.data.user.uuid != this.$auth.user().uuid) ||
                (this.record_lock?.user && res.data.user.uuid != this.record_lock.user.uuid)
              ) {
                this.$toast(`This lab result was locked by ${res.data.user.first_name} ${res.data.user.last_name} at ${this.$moment(res.data.created_at).format('h:mm a') }`, {
                  color: 'blue',
                  showClose: true,
                  timeout: 0,
                  icon: 'fal fa-user-lock',
                })
              }
            }
            this.$set(this, 'record_lock', res.data)
          })
      }
    },
  },
}
</script>
